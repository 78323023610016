#page-lawyer-information {
    h3 {
        line-height: 1;
    }
    .blue-block {
        background-image: url(../../Images/circles.svg);
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: initial;
        display: flex;
        align-items: center;
        color: #fff;
        height: 100%;
    }
}

#join-case,
#offer-legal,
#convenience-speed {
    .join-case-wrapper,
    .offer-legal-wrapper,
    .convenience-speed-wrapper {
        padding-bottom: 20px;
        overflow: hidden;
    }
}

#l-faqs {
    .l-faqs-wrapper {
        padding-top: 20px;
    }
}

@media (min-width: 1200px) {
    #join-case {
        .join-case-wrapper {
            padding-bottom: 20px;
        }
    }

    #offer-legal {
        .offer-legal-wrapper {
            padding-bottom: 20px;
        }
    }

    #convenience-speed {
        .convenience-speed-wrapper {
            padding-bottom: 20px;
        }
    }

    #l-faqs {
        .l-faqs-wrapper {
            padding-top: 20px;
        }
    }
}

@media (min-width: 1400px) {
    #join-case {
        .join-case-wrapper {
            padding-bottom: 30px;
        }
    }

    #offer-legal {
        .offer-legal-wrapper {
            padding-bottom: 30px;
        }
    }

    #convenience-speed {
        .convenience-speed-wrapper {
            padding-bottom: 30px;
        }
    }

    #l-faqs {
        .l-faqs-wrapper {
            padding-top: 30px;
        }
    }
}

@media (min-width: 1500px) {
    #join-case {
        .join-case-wrapper {
            padding-bottom: 40px;
        }
    }

    #offer-legal {
        .offer-legal-wrapper {
            padding-bottom: 40px;
        }
    }

    #convenience-speed {
        .convenience-speed-wrapper {
            padding-bottom: 40px;
        }
    }

    #l-faqs {
        .l-faqs-wrapper {
            padding-top: 40px;
        }
    }
}

@media (min-width: 1600px) {
    #join-case {
        .join-case-wrapper {
            padding-bottom: 45px;
        }
    }

    #offer-legal {
        .offer-legal-wrapper {
            padding-bottom: 45px;
        }
    }

    #convenience-speed {
        .convenience-speed-wrapper {
            padding-bottom: 45px;
        }
    }

    #l-faqs {
        .l-faqs-wrapper {
            padding-top: 45px;
        }
    }
}

@media (min-width: 1700px) {
    #join-case {
        .join-case-wrapper {
            padding-bottom: 50px;
        }
    }

    #offer-legal {
        .offer-legal-wrapper {
            padding-bottom: 50px;
        }
    }

    #convenience-speed {
        .convenience-speed-wrapper {
            padding-bottom: 50px;
        }
    }

    #l-faqs {
        .l-faqs-wrapper {
            padding-top: 50px;
        }
    }
}

@media (min-width: 1800px) {
    #join-case {
        .join-case-wrapper {
            padding-bottom: 55px;
        }
    }

    #offer-legal {
        .offer-legal-wrapper {
            padding-bottom: 55px;
        }
    }

    #convenience-speed {
        .convenience-speed-wrapper {
            padding-bottom: 55px;
        }
    }

    #l-faqs {
        .l-faqs-wrapper {
            padding-top: 55px;
        }
    }
}
