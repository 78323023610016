#lawyer-detail {
  .lawyer-profile {
    margin-top: 110px;
    background-color: #fff;
    border-radius: 0.25rem;
    padding: 25px 0;
  }

  .lawyer-picture {
    flex: 0 0 100px;
    margin: -120px 15px 15px;
    text-align: center;
    img {
      width: 180px;
      height: 180px;
      object-fit: cover;
      display: inline-block;
      max-width: 100%;
    }
  }

  .lawyer-exp {
    font-size: 16px;
    line-height: 1;
    display: block;
    margin-bottom: 20px;
    padding-left: 24px;
    position: relative;
    color: #434343;
    &::before {
      content: "";
      background: url(../../Images/experience.svg) top center no-repeat;
      width: 16px;
      height: 14px;
      display: inline-block;
      vertical-align: top;
      margin-top: -2px;
      position: absolute;
      left: 0px;
    }
  }

  .lawyer-practice-areas {
    padding-left: 24px;
    position: relative;

    &::before {
      content: "";
      background: url(../../Images/practice-areas.svg) top center no-repeat;
      width: 16px;
      height: 18px;
      display: inline-block;
      vertical-align: top;
      margin-top: 5px;
      position: absolute;
      left: 0px;
    }

    .lawyer-badge {
      font-weight: 400;
      color: #220c89;
      border: 1px solid #220c89;
      font-size: 12px;
      padding: 3px 4px 1px;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  // h1 {
  //   font-family: "TT Commons Bold";
  // }

  .practices-wrapper,
  .practices-wrapper-mobile {
    display: inline-block;

    .list-title {
      padding: 0.65rem;
      border-bottom: 0;
    }

    .q-practice-list {
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      //font-family: "TT Commons Bold";
      font-size: 14px;
      color: #220c89;
      &:active,
      &:hover {
        color: #fff;
        background-color: #220c89;
      }
    }

    .q-practice-action {
      //font-family: "TT Commons Bold";
      font-size: 14px;
      text-transform: uppercase;
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      background-color: #d8d8d8;
      &:hover,
      &:active {
        background-color: #e9ecef;
      }
    }
  }

  .practices-wrapper-mobile {
    background-color: #f2f2f2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

    button.btn-melon {
      width: calc(100% - 3rem);
      margin: 8px 24px;
      margin: 0.5rem 1.5rem;
    }
    &.open {
      button.btn-melon {
        width: 100%;
        margin: 0;
        background: #ed5452;
        color: #fff;
        border-radius: 0;
      }
    }
  }

  @media (min-width: 768px) {
    .lawyer-profile {
      background: none;
      box-shadow: none !important;
      margin-top: 0px;
      padding: 0;
    }

    .lawyer-picture {
      margin: -40px 15px 15px;
      img {
        width: 300px;
        height: auto;
        display: inline-block;
      }
    }
  }
}
