.contact-block {
  border-width: 0;
  .card-body {
    padding: 1.3rem;

    .header-icon {
      margin-right: 6px;
    }
  }
}
