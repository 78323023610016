.book-consult-wrapper {
  background: #fff;
  padding: 24px 16px 25px;
  // max-width: 340px;
  // margin-left: auto;
  // margin-right: 0;
  &.w-300 {
    width: 300px;
  }

  // .consult-header {
  //   font-family: "TT Commons Bold";
  //   font-size: 37px;
  //   color: #434343;
  //   line-height: 1;
  // }

  // .consult-subheader {
  //   font-family: "TT Commons Medium";
  //   font-size: 24px;
  //   color: #434343;
  //   line-height: 1;
  // }

  .list-group {
    padding: 0 0 0 1.25rem;

    .list-group-item {
      padding: 0;
      margin-bottom: 13px;
      border: none;

      span {
        color: #495057;
        font-size: 14px;
        margin-left: 15px;
        //font-family: "TT Commons Medium";
      }
    }
  }
}

@media (min-width: 768px) {
  .book-consult-wrapper {
    max-width: 340px;
    margin-left: auto;
    margin-right: 0;
    opacity: 0.91;
    padding: 16px;
  }
}
