.white-block-chart {
    background-color: #ffffff;
    border-width: 0;
    border-radius: 0.25rem;
    color: #555;

    .card-body {
        padding: 17px;
    }

    img {
        max-width: 114px;
    }
}
