#get-started {
  .get-started-wrapper {
    padding-top: 20px;
  }
}

@media (min-width: 1200px) {
  #get-started {
    .get-started-wrapper {
      padding-top: 20px;
    }
  }
}

@media (min-width: 1400px) {
  #get-started {
    .get-started-wrapper {
      padding-top: 30px;
    }
  }
}

@media (min-width: 1500px) {
  #get-started {
    .get-started-wrapper {
      padding-top: 40px;
    }
  }
}

@media (min-width: 1600px) {
  #get-started {
    .get-started-wrapper {
      padding-top: 45px;
    }
  }
}

@media (min-width: 1700px) {
  #get-started {
    .get-started-wrapper {
      padding-top: 50px;
    }
  }
}

@media (min-width: 1800px) {
  #get-started {
    .get-started-wrapper {
      padding-top: 55px;
    }
  }
}
