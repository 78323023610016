.practice-block {
  background-color: #fff;
  border-radius: 0.25rem;
  border-color: transparent;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  height: 100%;

  .card-body {
    padding: 40px 40px 24px;
  }

  h2 {
    // font-size: 24px;
    // font-family: "TT Commons Bold";
    margin-bottom: 20px;
    // line-height: 1;
  }

  &__intro {
    padding-bottom: 20px;

    p {
      margin: 0;
    }
  }

  a.btn {
    max-width: 275px;
    margin: 0 auto;
  }
}
