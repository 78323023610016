#block-top {
    background-image: url("../../Images/ManDesktopCall_New.webp");
    background-size: cover;
    .section-column {
        padding-top: 194px;
        padding-bottom: 68px;
    }

    .block-top-wrapper {
        position: relative;
    }

    .partner-logos {
        padding-bottom: 51px;
        padding-top: 32px;

        .partner-logo-item {
            padding-left: 13px;
            padding-right: 13px;
            margin-bottom: 12px;
            text-align: center;
        }

        .law-logo {
            width: 71px;
            height: 26px;
        }

        .straigth-logo {
            width: 80px;
            height: 18px;
        }

        .bb-tech-logo {
            width: 71px;
            height: 26px;
        }

        .acba-logo {
            width: 40px;
            height: 22px;
        }

        .b-vancouver-logo {
            width: 58px;
            height: 18px;
        }

        .access-pro-bono-logo {
            width: 78px;
            height: 17px;
        }

        .globe-logo {
            width: 29px;
            height: 32px;
        }

        @media (min-width: 768px) {
            .law-logo {
                width: 113px;
                height: 42px;
            }

            .straigth-logo {
                width: 128px;
                height: 29px;
            }

            .bb-tech-logo {
                width: 114px;
                height: 42px;
            }

            .acba-logo {
                width: 64px;
                height: 36px;
            }

            .b-vancouver-logo {
                width: 93px;
                height: 29px;
            }

            .access-pro-bono-logo {
                width: 124px;
                height: 27px;
            }

            .globe-logo {
                width: 47px;
                height: 51px;
            }

            .partner-logo-item {
                padding-left: 20px;
                padding-right: 20px;
                //margin: 0;
            }
        }

        // .partner-logo-item {
        //   height: 51px;

        //   img {
        //     max-height: 100%;
        //   }
        // }
    }
}

#why-qase {
    .q-card {
        img {
            margin-bottom: 11px;
        }
    }
    @media (min-width: 768px) {
        .q-card {
            padding: 0 35px;
            img {
                height: 80px;
                width: auto;
                margin-bottom: 8px;
            }
        }
    }
}

#how-qase-works {
    background-image: url("../../Images/Qase-Logo-Brandmark-Q-Dark-Grey@2x.png");
    background-repeat: no-repeat;
    background-position: 0 center;
    background-size: 50%;
}

#need-help {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAABkCAMAAAAL3/3yAAAAilBMVEXwVFHyZ2jyZmfwVlPwVVPxWFbxWVfxYmHxYWDxZGTxZWbxY2LxYF/xZWXxWFXxXl3xXV3xV1TxYGDwVFLxZmfxV1XxW1rxXVzxWljwVVLxZGPxX17yZmbwVlTxWVbxXFvxY2PxZGXxXFzxX13yZ2fxWlfxYWHxXl7xWlnxYmLxW1nxX1/xXFrxWlpLdcVFAAADCklEQVR4Xu3YB46rMBiFUWzTS3rvZXrZ//aeX2Y01gQiTXJNIOT+C0DiyGD7c8TPxMFuMnSdiscdTnZBLKCRQefFU32ncPrKe+kEUlwwzkFpbEPJ7fq7YLsN1n7XtWE2Rs0W2qylDJNqaaUF8EDHjtJHGolfE6UfdTIDlb5XFKQ0XfqjNHoQJ+chSkf+coqbtbQZ8Lag9aTlgkrveaXTZu+AGfAdwSsSVprnlP5sNrdh1j+YyfKU5H8l1UeUVnmly81WNsw822byNb+L4ko1M2u/SlSpbUFpllOybjazaVaR0kBcdQa2zcpX8nAl3MyzZJYUmsmkDkrb9Ovo3u/663RbI7PB9zNRJT24UtYruuC42qyX1cXMKCEryrGsVI7Z/g0wg5WU97bfDDSAc6HSY7HSabNH2Ewme+g7wv98DqxUqhn+h8aVzsbKwrwSZhZmFZwjwZOH8welsoqgO6zADDmfOahS/c1wpdNYcbgGuinU+dZhfI2bsKnLRulcrDhsWINHCg8bPBs8GzwbPG7GBs8GzwbPBs8GzwbPBs8GzwbPBs8GzwbPBs8GzwZfgRkbPBs8GzwbvN7o5hsphEzmejNggy+epVYKoqNrwCAKtNmSDf5HyR89Px0rHZs9Pes62rAGjysBZg1r8EapnVM6y6ytzRra4HEl3Kz6Bo8r4SMBM/sNHlYKIylKHxmFVZnhSgpQgs2UHbMmKOFm1Td4tdJKMynq0+CFnGmzlapbg8eV8AZfvplRQlbULTR4sTiYVXTHd813V0GDR8x0Tbiamet9dsLZQtxygxfxpleumatLV28Ti/o3eNwMV2pqgxdx0htrM1hpbJQa1+BxM6OUxKK5DR43M0ps8CJLUmNWpJQJNvhCs+mX0lQrpUaJDf6kGaDEBs8GzwbPBs8GzwbPBs8GXz8lNng2eDZ4Nng2eDZ4Nng2eDZ4Nng2eDZ4Nng2+NEdNXg2eDZ4Nng2eDZ4vCezwas7bfBs8GzwbPBs8Gzwn2zw55lt7q7Bh2zw9TRjg2eDjxvW4Nng/wEFAviS77abdAAAAABJRU5ErkJggg==);
    background-size: auto auto;
    background-size: initial;
    background-repeat: repeat;
    background-position: 0 0;
    background-color: #ed5452;
    color: #fff;
    overflow: hidden;
}

@media (max-width: 767px) {
    #block-top {
        background-image: url("../../Images/Mobile/ManDesktopCall_mobile2.webp");
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 8px;

        .container {
            max-width: none;
        }

        .section-column {
            margin-left: -15px;
            margin-right: -15px;
            padding-top: 100%;
            padding-bottom: 35px;
        }
    }
}

@media (max-width: 575px) {
    #block-top {
        background-image: url("../../Images/Mobile/ManDesktopCall_mobile2.webp");

        .section-column {
            padding-bottom: 23px;
        }
    }
}

@media (min-width: 768px) {
    #block-top {
        .partner-logos {
            padding-bottom: 65px;
        }
    }
}

@media (min-width: 1300px) {
    #block-top {
        background-image: url("../../Images/ManDesktopCall_New@2x.webp");
        background-position: 0;

        .section-column {
            padding-top: 225px;
            padding-bottom: 162px;
            margin-right: -8%;
        }
    }
}

@media (min-width: 1440px) {
    #block-top {
        .section-column {
            margin-right: -11%;
        }
    }
}
