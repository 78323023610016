.lawyer-block {
  border: 2px solid #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  //margin-bottom: 20px;
  height: 100%;
  cursor: pointer;
  &:hover {
    border-color: #ed5452;
    box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.21);
    text-decoration: none;
  }

  .card-body {
    padding: 15px;

    // h3 {
    //   white-space: nowrap;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   width: calc(100% - 30px);
    // }

    .lawyer-picture {
      margin-top: -40px;
      margin-right: 15px;
      img {
        height: 100px;
        width: 100px;
        object-fit: cover;
        object-position: center;
      }
    }

    .lawyer-exp {
      font-size: 16px;
      line-height: 1;
      display: block;
      margin-bottom: 10px;
      padding-left: 24px;
      position: relative;
      color: #434343;
      &::before {
        content: "";
        background: url(../../Images/experience.svg) top center no-repeat;
        width: 16px;
        height: 14px;
        display: inline-block;
        vertical-align: top;
        margin-top: -2px;
        position: absolute;
        left: 0px;
      }
    }

    .lawyer-practice-areas {
      padding-left: 24px;
      position: relative;

      &::before {
        content: "";
        background: url(../../Images/practice-areas.svg) top center no-repeat;
        width: 16px;
        height: 18px;
        display: inline-block;
        vertical-align: top;
        margin-top: 5px;
        position: absolute;
        left: 0px;
      }

      .lawyer-badge {
        font-weight: 400;
        color: #220c89;
        border: 1px solid #220c89;
        font-size: 12px;
        padding: 3px 4px 1px;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
