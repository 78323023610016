//TOP
.q-pt-20 {
  padding-top: 20px;
}

.q-pt-25 {
  padding-top: 20px;
}

.q-pt-45 {
  padding-top: 45px;
}

.q-pt-55 {
  padding-top: 55px;
}

.q-pt-57 {
  padding-top: 57px;
}

.q-pt-66 {
  padding-top: 66px;
}

.q-pt-128 {
  padding-top: 128px;
}

.q-pt-182 {
  padding-top: 182px;
}

.qr-pt-4 {
  padding-top: 1.95rem !important;
}
//RIGHT
.q-pr-10 {
  padding-right: 10px;
}

//BOTTOM
.q-pb-45 {
  padding-bottom: 45px;
}

.q-pb-55 {
  padding-bottom: 55px;
}

.q-pb-64 {
  padding-bottom: 64px;
}

.q-pb-70 {
  padding-bottom: 70px;
}

.q-pb-98 {
  padding-bottom: 98px;
}

.q-pb-115 {
  padding-bottom: 98px;
}

.qr-pb-3 {
  padding-bottom: 1.3rem !important;
}

//LEFT
.qr-pl-1_5 {
  padding-left: 1.5rem;
}
//X axis
.q-px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.qr-px-5 {
  padding-left: 3.9rem !important;
  padding-right: 3.9rem !important;
}

//Y axis

.q-py-5 {
  padding-bottom: 5px;
  padding-top: 5px;
}

.q-py-25 {
  padding-bottom: 25px;
  padding-top: 25px;
}

.q-py-40 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.q-py-45 {
  padding-bottom: 45px;
  padding-top: 45px;
}

.q-py-50 {
  padding-bottom: 50px;
  padding-top: 50px;
}

.q-py-55 {
  padding-bottom: 55px;
  padding-top: 55px;
}

.q-py-65 {
  padding-bottom: 65px;
  padding-top: 65px;
}

.q-py-75 {
  padding-bottom: 75px;
  padding-top: 75px;
}

.q-py-100 {
  padding-bottom: 100px;
  padding-top: 100px;
}

.q-py-110 {
  padding-bottom: 110px;
  padding-top: 110px;
}

.q-py-128 {
  padding-bottom: 128px;
  padding-top: 128px;
}

.q-py-130 {
  padding-bottom: 130px;
  padding-top: 130px;
}

.q-py-218 {
  padding-bottom: 218px;
  padding-top: 218px;
}

.qr-py-3 {
  padding-bottom: 1.3rem !important;
  padding-top: 1.3rem !important;
}

.qr-py-5 {
  padding-top: 3.9rem !important;
  padding-bottom: 3.9rem !important;
}

//ALL
.qr-p-3 {
  padding: 1.3rem !important;
}

@media (min-width: 768px) {
  //TOP
  .qr-pt-md-40 {
    padding-top: 40px !important;
  }

  .q-pt-md-27 {
    padding-top: 27px;
  }

  .q-pt-md-38 {
    padding-top: 38px;
  }

  .q-pt-md-55 {
    padding-top: 55px;
  }

  .q-pt-md-94 {
    padding-top: 94px;
  }

  .q-pt-md-143 {
    padding-top: 143px;
  }

  .q-pt-md-161 {
    padding-top: 161px;
  }

  .q-pt-md-192 {
    padding-top: 192px;
  }

  //RIGHT

  //BOTTOM

  .qr-pb-md-3 {
    padding-bottom: 1.3rem !important;
  }

  .qr-pb-md-5 {
    padding-bottom: 3.9rem !important;
  }

  .q-pb-md-40 {
    padding-bottom: 40px;
  }

  .q-pb-md-55 {
    padding-bottom: 55px;
  }

  .q-pb-md-72 {
    padding-bottom: 72px;
  }

  .q-pb-md-76 {
    padding-bottom: 76px;
  }

  .q-pb-md-90 {
    padding-bottom: 90px;
  }

  .q-pb-md-100 {
    padding-bottom: 100px;
  }

  .q-pb-md-173 {
    padding-bottom: 173px;
  }

  .q-pb-md-184 {
    padding-bottom: 184px;
  }
  //LEFT

  //X axis
  .q-px-md-3 {
    padding-left: 1.3rem !important;
    padding-right: 1.3rem !important;
  }

  //Y axis
  .q-py-md-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .q-py-md-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .q-py-md-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .q-py-md-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .q-py-md-143 {
    padding-top: 143px;
    padding-bottom: 143px;
  }

  .q-py-md-155 {
    padding-top: 155px;
    padding-bottom: 155px;
  }

  .q-py-md-173 {
    padding-top: 173px;
    padding-bottom: 173px;
  }

  .q-py-md-184 {
    padding-top: 184px;
    padding-bottom: 184px;
  }

  .qr-py-md-4 {
    padding-top: 1.95rem !important;
    padding-bottom: 1.95rem !important;
  }

  .qr-py-md-5 {
    padding-top: 3.9rem !important;
    padding-bottom: 3.9rem !important;
  }

  //ALL
  .qr-p-md-5 {
    padding: 3.9rem !important;
  }
}

@media (min-width: 992px) {
  //TOP
  .q-pt-lg-3 {
    padding-top: 1.3rem !important;
  }

  .q-pt-lg-50 {
    padding-top: 50px !important;
  }

  //RIGHT

  //BOTTOM

  //LEFT
  .q-pl-lg-3 {
    padding-left: 1.3rem !important;
  }

  //X axis
  .q-px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .q-px-lg-5 {
    padding-left: 3.9rem !important;
    padding-right: 3.9rem !important;
  }

  //Y axis
  .q-py-55-lg {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .q-py-75-lg {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .q-py-100-lg {
    padding-bottom: 100px;
    padding-top: 100px;
  }

  .q-py-110-lg {
    padding-bottom: 110px;
    padding-top: 110px;
  }

  .q-py-130-lg {
    padding-bottom: 130px;
    padding-top: 130px;
  }

  .q-py-lg-3 {
    padding-bottom: 1.3rem !important;
    padding-top: 1.3rem !important;
  }

  .qr-py-lg-5 {
    padding-top: 3.9rem !important;
    padding-bottom: 3.9rem !important;
  }
}

@media (min-width: 1200px) {
  //TOP
  .q-pt-xl-5 {
    padding-top: 3.9rem !important;
  }

  //RIGHT

  //BOTTOM
  .q-pb-xl-45 {
    padding-bottom: 45px;
  }

  //LEFT
  .q-pl-xl-4 {
    padding-left: 1.95rem !important;
  }

  //X axis

  //Y axis
  .q-py-xl-5 {
    padding-bottom: 3.9rem !important;
    padding-top: 3.9rem !important;
  }
}
