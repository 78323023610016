details {
  &.accordion-item {
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) !important;

    summary {
      margin: 0;
      outline: none;
      border: 0;
      display: block;
      padding: 0;
      color: #220c89;
      margin: 0;
      cursor: pointer;

      &::-webkit-details-marker {
        display: none;
      }
    }

    .accordion_item__accordion-title {
      padding: 8px;
      text-align: left;
      align-items: center;
      justify-content: space-between;
      display: flex;

      &::after {
        content: "";
        background-image: url(../../Images/open.svg);
        background-position: center;
        background-repeat: no-repeat;
        text-align: right;
        margin: 0;
        color: #220c89;
        display: block;
        //vertical-align: top;
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
        margin-left: 8px;
      }
    }

    &[open=""] {
      .accordion_item__accordion-title::after {
        content: "";
        transform: rotate(180deg);
      }
    }

    .accordion_item__accordion-body {
      padding: 0 8px 8px;
      // font-size: 19px;
      // line-height: 25px;
    }

    @media (min-width: 768px) {
      .accordion_item__accordion-title {
        padding: 18px 40px;
      }
      .accordion_item__accordion-body {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }
}
