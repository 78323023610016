#pa-seo {
  // margin-top: 54px;

  .pa-header {
    background-size: cover;
    background-position: top center;

    &.family_law {
      background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url("../../Images/PASeo/persnickety.jpg");
    }

    &.child_custody {
      background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url("../../Images/PASeo/child-constudy-banner.jpg");
    }

    &.separation_and_divorce {
      background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url("../../Images/PASeo/devorce-banner.jpg");
    }

    &.spousal_support {
      background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url("../../Images/PASeo/spousal-support-banner.jpg");
    }

    &.child_support {
      background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url("../../Images/PASeo/child-support-banner.jpg");
    }

    &.matrimonial_home {
      background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url("../../Images/PASeo/matrimonial-home-banner.jpg");
    }

    &.employment_and_benefits {
      background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url("../../Images/PASeo/employment_and_benefits-banner.jpg");
    }

    &.wrongful_dismissal_or_discrimination {
      background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url("../../Images/PASeo/wrongful-banner.jpg");
    }

    &.contracts_new_job_or_termination {
      background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url("../../Images/PASeo/contracts-banner.jpg");
    }

    &.wills_and_estates {
      background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url("../../Images/PASeo/wills-estates-banner.jpg");
    }

    &.estate_litigation {
      background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url("../../Images/PASeo/estate-ligitation-banner.jpg");
    }

    &.prenuptial_and_co-habitation_agreements {
      background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url("../../Images/PASeo/prenuptial-banner.jpg");
    }
  }

  .partner-logos {
    padding-bottom: 51px;
    padding-top: 32px;

    .partner-logo-item {
      padding-left: 13px;
      padding-right: 13px;
      margin-bottom: 12px;
      text-align: center;
    }

    .law-logo {
      width: 71px;
      height: 26px;
    }

    .straigth-logo {
      width: 80px;
      height: 18px;
    }

    .bb-tech-logo {
      width: 71px;
      height: 26px;
    }

    .acba-logo {
      width: 40px;
      height: 22px;
    }

    .b-vancouver-logo {
      width: 58px;
      height: 18px;
    }

    .access-pro-bono-logo {
      width: 78px;
      height: 17px;
    }

    .globe-logo {
      width: 29px;
      height: 32px;
    }

    @media (min-width: 768px) {
      .law-logo {
        width: 113px;
        height: 42px;
      }

      .straigth-logo {
        width: 128px;
        height: 29px;
      }

      .bb-tech-logo {
        width: 114px;
        height: 42px;
      }

      .acba-logo {
        width: 64px;
        height: 36px;
      }

      .b-vancouver-logo {
        width: 93px;
        height: 29px;
      }

      .access-pro-bono-logo {
        width: 124px;
        height: 27px;
      }

      .globe-logo {
        width: 47px;
        height: 51px;
      }

      .partner-logo-item {
        padding-left: 20px;
        padding-right: 20px;
        //margin: 0;
      }
    }

    // .partner-logo-item {
    //   height: 51px;

    //   img {
    //     max-height: 100%;
    //   }
    // }
  }

  .legal-item {
    text-align: center;

    .legal-item-image {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      background-color: #ffffff;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
  }

  .practice-block-2 {
    height: 100%;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
    border-radius: 8px;

    .card-body {
      padding: 32px 16px;
    }
    .card-footer {
      background-color: #ffffff;
      border: none;
      padding-bottom: 24px;
    }
  }

  .qase-perks {
    .card {
      border-radius: 8px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
      border: none;
      > * {
        z-index: 100;
      }

      .card-body {
        padding: 32px;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          background-image: url("../../Images/wave-445.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center bottom;
        }
      }
    }
  }

  .get-started {
    background-image: url("../../Images/PASeo/Qase-Logo-Dark-Grey.png");
    background-repeat: no-repeat;
    background-position: left center;
  }

  .divider-hr {
    max-width: 282px;
    margin: 0 auto 15px;
    border-bottom: 1px solid #dcdcdc;
  }
}

@media (min-width: 768px) {
  // #pa-seo {
  //   margin-top: 64px;
  // }
}

@media (min-width: 1300px) {
  #pa-seo {
    .pa-header {
      &.family_law {
        background-size: cover;
        background-position: center;
      }
      .header-wrapper {
        padding-top: 208px;
        // padding-bottom: 208px;
      }
    }
  }
}

@media (max-width: 767px) {
  #pa-seo {
    .legal-item {
      .legal-item-image {
        height: 70px;
        width: 70px;

        img {
          max-width: 60%;
        }
      }
    }
  }
}
