.card.testimonials-card {
  border: 0;
  background-color: #f6f6f6;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.22);
  margin-bottom: 40px;

  > * {
    z-index: 100;
  }

  &.card-revers {
    .card-body::after {
      transform: scaleX(-1);
    }
  }

  .card-body {
    position: relative;
    overflow: hidden;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 41px;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-image: url("../../Images/path-wave.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center bottom;
    }
  }

  .card-footer {
    border: 0;
    background-color: #f6f6f6;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    // padding-top: 15px;
  }
}

@media (min-width: 768px) {
  .card.testimonials-card {
    margin-bottom: 0;
    height: 100%;

    .card-body {
      padding-left: 56px;
      padding-right: 56px;
      padding-top: 31px;
      padding-bottom: 46px;
    }

    .card-footer {
      padding-bottom: 24px;
      // padding-left: 56px;
      // padding-right: 56px;
      padding-top: 5px;
      // padding-top: 15px;
    }
  }
}
