#policy-page {
  // strong {
  //   font-family: "TT Commons Bold";
  // }

  p {
    margin-bottom: 1.5rem;
  }

  hr {
    margin-top: 20.8px;
    margin-top: 1.3rem;
    margin-bottom: 20.8px;
    margin-bottom: 1.3rem;
    border: 0;
    border-top: 1px solid #220c89;
  }
}
