#footer {
  background: #121429 url("../../Images/big-q-transparent.png") left center
    no-repeat;
  background-size: contain;
  color: #fff;
  // font-size: 14px;
  // line-height: 24px;
  padding-top: 33px;
  padding-bottom: 26px;

  a {
    color: #fff;
  }

  .footer-logo {
    margin-bottom: 32px;

    img {
      width: 120px;
      height: 38px;
    }
  }

  .footer-menu {
    li a,
    li span {
      // display: block;
      // margin-bottom: 15px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .social-button {
    font-size: 24px;
    // margin-top: 20px;
    margin-top: 32px;
    margin-bottom: 30px;

    a {
      //padding-right: 5px;
      margin-left: 36px;
      display: inline-block;

      &:first-child {
        margin-left: 0;
      }
      // svg {
      //   height: 24px;
      //   width: 24px;
      // }
    }
  }

  .col-auto {
    flex: 0 1 150px;
  }
}

@media (min-width: 768px) {
  #footer {
    padding-bottom: 24px;

    .footer-logo {
      margin-bottom: 22.54px;
      img {
        width: auto;
        height: 48px;
      }
    }

    .social-button {
      margin-bottom: 0;
    }
  }
}
