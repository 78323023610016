h1,
.h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 56px;
}

h2,
.h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
}

h3,
.h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
}

.p2 {
    font-size: 18px;
}

@media (min-width: 768px) {
    h1,
    .h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 64px;
    }

    h2,
    .h2 {
        font-size: 36px;
        font-weight: 700;
        line-height: 48px;
    }

    h3,
    .h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
    }

    .p2 {
        font-size: 18px;
    }
    .lead {
        font-size: calc(28px * 0.9);
    }
}

.f-bold {
    font-weight: bold;
}
