.qase-navbar {
  nav {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0;

    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: all;
    -o-transition-property: all;
    transition-property: all;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;

    .navbar-toggler {
      outline: none !important;
      border: none;
      padding-left: 5px;
      padding-right: 5px;
      .navbar-toggler-icon {
        background: transparent url(../../Images/mobile-menu.svg) center
          no-repeat;
      }
    }

    .navbar-brand {
      img {
        height: 20px;
        width: 64px;
      }
    }

    .marketing-menu {
      -webkit-transition-duration: 0.2s;
      -o-transition-duration: 0.2s;
      transition-duration: 0.2s;
      -webkit-transition-property: all;
      -o-transition-property: all;
      transition-property: all;
      -webkit-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease;
      -webkit-transition-delay: 0s;
      -o-transition-delay: 0s;
      transition-delay: 0s;

      .dropdown-toggle::after {
        content: none;
      }

      .navbar-nav {
        .nav-item {
          margin-left: 15px;
          .nav-link {
            font-size: 16px;
            //font-family: "TT Commons Medium";
            padding: 10px 0px;
            cursor: pointer;
            color: #343a40;
            line-height: 1;
          }
        }
      }

      .dropdown-menu {
        margin-top: 0;
        border: 0px;
        width: 250px;
        border-radius: 0 5px 5px 5px;
        padding: 10px 0;
        background-color: #220c89;
        .dropdown-item,
        .dropdown-item:visited {
          padding: 5px 0 5px 10px;
          color: #fff;
          &:hover,
          &.active,
          &:active {
            color: #4cbacc;
            background-color: #220c89;
          }
        }
      }
    }

    .account-options {
      //font-family: "TT Commons Medium";

      .navbar-nav a.nav-link {
        font-size: 13px;
        font-weight: 500;
        padding: 3px 10px;
        line-height: 1;
        color: #220c89;
        &:hover {
          font-weight: 600;
        }
      }

      .account-options-divider {
        box-sizing: border-box;
        height: 20px;
        width: 0px;
        border-left: 3px solid #220c89;
      }
      // ul li:first-child {
      //   border-right: 2px solid #220c89;
      // }
    }
  }

  &.blue-navbar {
    nav {
      background-color: #220c89;

      .navbar-toggler > .navbar-toggler-icon {
        background-image: url(../../Images/mobile-menu-white.svg);
      }

      .marketing-menu {
        .navbar-nav {
          .nav-item {
            .nav-link {
              color: #ffffff;
            }
          }
        }
      }

      .account-options {
        .navbar-nav a.nav-link {
          color: #ffffff;
        }

        .account-options-divider {
          border-color: #fff;
        }
        // ul li:first-child {
        //   border-right-color: #ffffff;
        // }
      }
    }
  }
}

@media (max-width: 767px) {
  .qase-navbar {
    nav {
      padding-top: 0px;
      padding-bottom: 0px;
      background-color: #ffffff;
      .marketing-menu {
        position: fixed;
        top: 0;
        left: -260px;
        height: 100% !important;
        z-index: 5;
        margin: 0 !important;
        width: 260px;
        max-width: 85%;
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;

        background-color: #220c89;
        padding: 1.3rem;

        .navbar-nav {
          margin-left: 10px;
          .nav-item {
            margin-left: 0;
            .nav-link {
              font-size: 16px;
              padding: 15px 10px;
              cursor: pointer;
              color: #fff;
            }

            &:hover {
              .nav-link {
                font-weight: 500;
              }
            }

            .dropdown-menu {
              display: block;
              width: 100%;
              padding-top: 0;
              padding-bottom: 0;
              margin-left: 10px;
              .dropdown-item,
              .dropdown-item:visited {
                font-size: 16px;
                padding: 5px 20px;
                &:hover {
                  color: #ffffff;
                }
              }
            }
          }
        }
      }

      .navbar-brand {
        line-height: 0;
        padding-top: 17px;
        padding-bottom: 17px;
        margin: 0;
        margin-left: 40px;
        // img {
        //   height: 20px;
        // }
      }

      .account-options {
        .navbar-nav {
          a.nav-link {
            font-size: 12px;
            font-weight: 700;
            padding: 3px 10px;
          }
        }

        .account-options-divider {
          box-sizing: border-box;
          height: 15px;
          width: 0px;
          border-left: 2px solid #220c89;
        }
      }
    }
  }

  body {
    margin-left: 0;
    width: 100%;
    overflow-x: hidden;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: all;
    -o-transition-property: all;
    transition-property: all;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;

    &.main-nav--overlay-opened {
      margin-left: 260px;
      width: 100%;

      .qase-navbar {
        nav {
          left: 260px;
          width: 100%;

          .marketing-menu {
            left: 0;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          }
        }
      }

      .white-navbar {
        nav {
          .navbar-toggler > .navbar-toggler-icon {
            background: transparent url(../../Images/menu-close.svg) center
              no-repeat;
          }
        }
      }

      .blue-navbar {
        nav {
          .navbar-toggler > .navbar-toggler-icon {
            background: transparent url(../../Images/menu-close-white.svg)
              center no-repeat;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .qase-navbar {
    nav {
      padding: 10px 0;
      .navbar-brand {
        // padding-top: 0.3125rem;
        // padding-bottom: 0.3125rem;
        // margin-right: 1rem;
        // margin-left: 0;
        padding: 6px 0;
        // margin-left: 25px;
        margin-right: 13px;
        img {
          height: 32px;
          width: 102px;
        }
      }

      .marketing-menu {
        .navbar-nav {
          .nav-item {
            .nav-link {
              // letter-spacing: 0.57px;
              font-size: 15px;
            }
            &.active {
              font-weight: 600;
            }
          }
          .nav-item.dropdown {
            &:hover {
              border-radius: 5px 5px 0 0;
              background-color: #220c89;

              .nav-link {
                color: #fff;
                // font-weight: 500;
                // letter-spacing: 0.57px;
              }

              .dropdown-menu {
                display: block;
              }
            }
          }
        }
      }

      .account-options {
        .navbar-nav a.nav-link {
          font-size: 15px;
          // letter-spacing: 0.57px;
        }
      }
    }

    &.blue-navbar {
      nav {
        .marketing-menu {
          .navbar-nav {
            .nav-item.dropdown {
              &:hover {
                border-radius: 5px 5px 0 0;
                background-color: #ffffff;

                .nav-link {
                  color: #220c89;
                }

                .dropdown-menu {
                  display: block;
                }
              }
            }
          }
        }

        .dropdown-menu {
          background-color: #ffffff;
          .dropdown-item,
          .dropdown-item:visited {
            color: #343a40;
            &:hover,
            &.active,
            &:active {
              color: #4cbacc;
              background-color: #ffffff;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .qase-navbar {
    nav {
      .marketing-menu {
        .navbar-nav {
          .nav-item {
            margin-left: 25px;
            .nav-link {
              font-size: 16px;
              padding: 10px;
              line-height: 24px;
            }
          }
        }
      }

      .account-options {
        .navbar-nav a.nav-link {
          font-size: 18px;
          font-weight: bold;
          padding: 3px 16px;
          line-height: 27px;
        }
      }
    }
  }
}
