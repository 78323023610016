@import "~video-react/styles/scss/video-react";

#ppc-landing {
  margin-top: 54px;
  // .header {
  //   background-image: url(../../Images/PPC/ATF-image.jpg);
  //   background-repeat: no-repeat;
  //   background-position: center center;
  //   background-size: cover;
  //   position: relative;
  // }

  .heading-1 {
    font-family: "Lato", sans-serif !important;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;
  }

  .subheading-1 {
    font-family: "Lato", sans-serif !important;
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.2;
  }

  .subheading-2 {
    font-family: "Lato", sans-serif !important;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
  }

  .subheading-3 {
    font-family: "Lato", sans-serif !important;
    color: #fff;
    font-size: 20px;
    line-height: 1.2;
  }

  // .btn-melon,
  // .btn-blue {
  //   max-width: 250px;
  // }

  .circled-wrapper {
    width: 50px;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // .card {
  //   border-width: 2px;
  //   border-radius: 0;
  //   height: 100%;
  // }

  // .card-body {
  //   height: calc(100% - 100px);
  //   display: flex;
  //   align-items: center;
  // }

  // .card-footer {
  //   background: transparent;
  //   border-top: none;
  //   text-align: center;
  //   height: 100px;
  // }

  .hr {
    width: 70%;
    margin-top: 0;
  }

  // p {
  //   font-size: 16px;
  // }

  .need-help {
    background-image: url(../../Images/melon_1.png);
    background-size: initial;
    background-repeat: repeat;
    background-position: top left;
    background-color: #ed5452;
    color: #fff;
    overflow: hidden;
  }

  .q-carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    width: 100%;
    margin: 0 15px;

    .img-fluid {
      max-height: 100%;
    }
  }

  .q-testimonials-carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 340px;
    width: 100%;
    margin: 0 15px;
  }

  .arrow-button {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: rgba(81, 69, 139, 0.8);
    color: #333;
    box-shadow: 0 0 2px 0px #fff;
    border-radius: 50%;
    border: none;
    width: 34px;
    height: 30px;
    line-height: 30px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
      cursor: not-allowed;
    }

    &:hover:enabled {
      background-color: #220c89;
    }
  }

  .partner-logos {
    padding-bottom: 51px;
    padding-top: 32px;

    .partner-logo-item {
      padding-left: 13px;
      padding-right: 13px;
      margin-bottom: 12px;
      text-align: center;
    }

    .law-logo {
      width: 71px;
      height: 26px;
    }

    .straigth-logo {
      width: 80px;
      height: 18px;
    }

    .bb-tech-logo {
      width: 71px;
      height: 26px;
    }

    .acba-logo {
      width: 40px;
      height: 22px;
    }

    .b-vancouver-logo {
      width: 58px;
      height: 18px;
    }

    .access-pro-bono-logo {
      width: 78px;
      height: 17px;
    }

    .globe-logo {
      width: 29px;
      height: 32px;
    }

    @media (min-width: 768px) {
      .law-logo {
        width: 113px;
        height: 42px;
      }

      .straigth-logo {
        width: 128px;
        height: 29px;
      }

      .bb-tech-logo {
        width: 114px;
        height: 42px;
      }

      .acba-logo {
        width: 64px;
        height: 36px;
      }

      .b-vancouver-logo {
        width: 93px;
        height: 29px;
      }

      .access-pro-bono-logo {
        width: 124px;
        height: 27px;
      }

      .globe-logo {
        width: 47px;
        height: 51px;
      }

      .partner-logo-item {
        padding-left: 20px;
        padding-right: 20px;
        //margin: 0;
      }
    }

    // .partner-logo-item {
    //   height: 51px;

    //   img {
    //     max-height: 100%;
    //   }
    // }
  }
}
@media (min-width: 768px) {
  #ppc-landing {
    margin-top: 64px;
    .q-carousel-item .img-fluid {
      max-height: 100px;
      max-width: 110px;
    }

    // .q-testimonials-carousel-item {
    //   height: 300px;
    // }

    .arrow-button {
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      background-color: rgba(81, 69, 139, 0.8);
      color: #333;
      box-shadow: 0 0 2px 0px #fff;
      border-radius: 50%;
      border: none;
      width: 50px;
      height: 50px;
      min-width: 50px;
      line-height: 50px;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      cursor: pointer;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;

      &:disabled {
        cursor: not-allowed;
      }

      &:hover:enabled {
        background-color: #220c89;
      }
    }
  }
}
