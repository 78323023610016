.lawyer-information-block {
    border: none;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 1.3rem;

    .card-body {
        padding: 46px 30px;

        .media-body {
            flex: 0 0 75%;
            max-width: 75%;
            padding-right: 15px;
        }

        h3 {
            color: #220c89;
        }
    }
}
