#contact-page {
  .form-control:focus {
    color: #343a40;
    background-color: #fff;
    border-color: #4b28ec;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 0.2rem rgba(34, 12, 137, 0.25);
  }

  .form-control:-ms-input-placeholder {
    color: #343a40;
    opacity: 1;
  }

  .form-control::placeholder {
    color: #343a40;
    opacity: 1;
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
}
