//TOP
.qr-mt-3 {
    margin-top: 1.3rem !important;
}

.q-mt-58 {
    margin-top: 58px;
}
//RIGHT
.q-mr-8 {
    margin-right: 8px;
}

.q-mr-10 {
    margin-right: 10px;
}

.q-mr-15 {
    margin-right: 15px;
}

//BOTTOM
// .mb-5 {
//   margin-bottom: 3.9rem !important;
// }

// .mb-6 {
//   margin-bottom: 3rem !important;
// }

.q-mb-15 {
    margin-bottom: 15px;
}

.q-mb-20 {
    margin-bottom: 20px;
}

.q-mb-22 {
    margin-bottom: 22px;
}

.q-mb-24 {
    margin-bottom: 24px;
}

.q-mb-25 {
    margin-bottom: 25px;
}

.q-mb-30 {
    margin-bottom: 30px;
}

.q-mb-32 {
    margin-bottom: 32px;
}

.q-mb-33 {
    margin-bottom: 33px;
}

.q-mb-38 {
    margin-bottom: 38px;
}

.q-mb-39 {
    margin-bottom: 39px;
}

.q-mb-40 {
    margin-bottom: 40px;
}

.q-mb-45 {
    margin-bottom: 45px;
}

.q-mb-50 {
    margin-bottom: 50px;
}

.q-mb-55 {
    margin-bottom: 55px;
}

.q-mb-56 {
    margin-bottom: 56px;
}

.q-mb-60 {
    margin-bottom: 60px;
}

.q-mb-104 {
    margin-bottom: 104px;
}

.q-mb-149 {
    margin-bottom: 149px;
}

.q-mb-65 {
    margin-bottom: 65px;
}

.q-mb-1_3 {
    margin-bottom: 1.3rem;
}

.q-mb-1_5 {
    margin-bottom: 1.5rem;
}

.q-mb-1_95 {
    margin-bottom: 1.95rem;
}

.qr-mb-1 {
    margin-bottom: 0.325rem !important;
}

//LEFT
.q-ml-10 {
    margin-left: 10px;
}

.q-ml-15 {
    margin-left: 15px;
}

//X axis

//Y axis
.qr-my-3 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important;
}

.qr-my-4 {
    margin-top: 1.95rem !important;
    margin-bottom: 1.95rem !important;
}

//ALL

@media (min-width: 768px) {
    //TOP

    //RIGHT

    //BOTTOM
    .qr-mb-md-0 {
        margin-bottom: 0 !important;
    }

    .qr-mb-md-4 {
        margin-bottom: 1.95rem !important;
    }

    .q-mb-md-32 {
        margin-bottom: 32px;
    }

    .q-mb-md-40 {
        margin-bottom: 40px;
    }

    .q-mb-md-42 {
        margin-bottom: 42px;
    }

    .q-mb-md-50 {
        margin-bottom: 50px;
    }

    .q-mb-md-66 {
        margin-bottom: 66px;
    }

    .q-mb-md-100 {
        margin-bottom: 100px;
    }

    //LEFT
}

@media (min-width: 992px) {
    //TOP
    .q-mt-lg-2 {
        margin-top: 0.65rem !important;
    }

    //RIGHT

    //BOTTOM
    .qr-mb-lg-4 {
        margin-bottom: 1.95rem !important;
    }

    .q-mb-lg-100 {
        margin-bottom: 100px;
    }
}

@media (min-width: 1200px) {
    //TOP
    .q-mt-xl-4 {
        margin-top: 1.95rem !important;
    }

    //RIGHT

    //BOTTOM

    //LEFT
    .q-ml-xl-3 {
        margin-left: 1.3rem !important;
    }

    //X axis

    //Y axis
}
