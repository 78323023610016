.blue-hero {
  background-color: #220c89;
  // color: #fff;
  margin-top: 54px;

  .blue-hero-wrapper {
    padding-bottom: 20px;

    // h1 {
    //   line-height: 1;
    //   font-family: "TT Commons Bold";
    // }

    p {
      margin-bottom: 1.5rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .blue-hero-area-detail {
    padding: 30px 0 60px;
    background: #220c89 url(../../Images/wave-gray.svg) center bottom -2px no-repeat;
    background-size: 102%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-weight: 400;
      font-size: 36px;
      color: #fff;
      text-align: center;
    }
  }
}

@media (min-width: 768px) {
  .blue-hero {
    margin-top: 64px;
    .blue-hero-area-detail {
      padding-top: 10px;
    }
  }
}

@media (min-width: 1200px) {
  .blue-hero {
    .blue-hero-wrapper {
      padding-bottom: 20px;
    }
  }
}

@media (min-width: 1400px) {
  .blue-hero {
    .blue-hero-wrapper {
      padding-bottom: 30px;
    }
  }
}

@media (min-width: 1500px) {
  .blue-hero {
    .blue-hero-wrapper {
      padding-bottom: 40px;
    }
  }
}

@media (min-width: 1600px) {
  .blue-hero {
    .blue-hero-wrapper {
      padding-bottom: 45px;
    }
  }
}

@media (min-width: 1700px) {
  .blue-hero {
    .blue-hero-wrapper {
      padding-bottom: 50px;
    }
  }
}

@media (min-width: 1800px) {
  .blue-hero {
    .blue-hero-wrapper {
      padding-bottom: 55px;
    }
  }
}
