#practice_area {
  h1,
  h2,
  h3,
  h4,
  .h1,
  .h2,
  .h3,
  .h4 {
    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1;
    color: #220c89;
    // font-family: "TT Commons Bold";
  }

  h4,
  .h4 {
    font-size: 1.6875rem;
  }

  h5,
  .h5 {
    font-size: 1.40625rem;
  }

  // strong {
  //   font-family: "TT Commons Bold";
  // }

  p {
    margin-bottom: 1.5rem;
  }

  ul {
    margin: 0;
    padding: 0 0 24px 20px;
    padding: 0 0 1.5rem 1.25rem;
  }

  hr {
    margin-top: 20.8px;
    margin-top: 1.3rem;
    margin-bottom: 20.8px;
    margin-bottom: 1.3rem;
    border: 0;
    border-top: 1px solid #220c89;
  }
}
