@import "~bootstrap/scss/bootstrap";
@import "./margins";
@import "./paddings";
@import "./fonts";
// @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

body {
    font-family: "Lato", sans-serif;
    color: #333333;
    font-display: swap;
}

// .App {
//   margin-top: 50px;
// }

a {
    color: #220c89;
    text-decoration: none;
    background-color: transparent;

    &:hover {
        color: #220c89;
        text-decoration: underline;
    }
}

.btn {
    //font-family: "TT Commons Bold";
    text-transform: uppercase;
    padding: 15px 22px;
    font-size: 16px;
    font-weight: 700;
    border-radius: 8px;
    // line-height: 1;
}

.btn-lg {
    font-size: 18px;
}

.btn-melon {
    background: #ed5452;
    color: #fff;
    &:hover {
        background: #fff;
        color: #ed5452;
        border-color: #ed5452;
    }
}

.btn-blue {
    background: #220c89;
    color: #fff;
    &:hover {
        background: #fff;
        color: #220c89;
        border-color: #220c89;
    }
}

.btn-white {
    background: #fff;
    color: #220c89;
    &:hover {
        background: #220c89;
        color: #fff;
        border-color: #fff;
    }
}

/* PADDINGS */

/* MARGINS */

/* COLORS */
.q-color-white {
    color: #ffffff;
}

.q-color-midnightblue {
    color: #220c89 !important;
}

.q-color-darkslategray {
    color: #434343;
}

.q-color-dimgray {
    color: #555555;
}

/* BACKGROUND COLORS */
.q-bg-midnightblue {
    background-color: #220c89;
}

.q-bg-whitesmoke {
    background-color: #f2f3ee;
}

/* FONT SIZES */
.q-font-24 {
    font-size: 24px;
}

.q-font-28 {
    font-size: 28px;
}

.q-font-48 {
    font-size: 48px;
}

/* FONT FAMILY */
.q-ff-medium {
    font-family: "TT Commons Medium";
}

.q-ff-bold {
    font-family: "TT Commons Bold";
}

/* LINE HEIGHTS */
.q-line-height1 {
    line-height: 1;
}

/*  DIVIDERS  */
.divider {
    z-index: 1;
    &.reverse {
        transform: rotate(180deg);
        margin-bottom: -1px;
    }

    &.flip-x {
        transform: rotateY(180deg);
        margin-bottom: -1px;
    }

    &.flip-y {
        transform: rotateX(180deg);
        margin-bottom: -1px;
    }

    &.divider-bottom {
        position: absolute;
        width: 100%;
        bottom: 0;

        &.wave {
            max-height: 125px;
            //overflow: hidden;

            & > svg .mono {
                fill: #220c89;
            }
        }

        &.wave-gray {
            max-height: 125px;
            //overflow: hidden;
            & > svg .mono {
                fill: #f2f2f2;
            }
        }

        &.wave-white {
            & > svg .mono {
                fill: #ffffff;
            }
        }

        &.duotone-gray {
            & > svg .duo {
                fill: rgba(242, 242, 242, 0.3);
            }

            & > svg .mono {
                fill: #f2f3ee;
            }
        }
    }

    &.divider-top {
        position: absolute;
        width: 100%;
        top: 0;

        &.wave {
            max-height: 125px;
            //overflow: hidden;

            & > svg .mono {
                fill: #220c89;
            }
        }

        &.wave-gray {
            max-height: 125px;
            //overflow: hidden;

            & > svg .mono {
                fill: #f2f2f2;
            }
        }
    }
}

/*  BORDERS  */
.q-brd-right {
    border-right: 1px solid;
}

.q-brd-top-0 {
    border-top-width: 0 !important;
}

.q-brd-darkslategray {
    border-color: #434343;
}

/* VIDEOS */
.video-embed-responsive-video {
    position: relative;

    &::after {
        content: "";
        display: block;
        padding-bottom: 56.25%;
    }

    iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
    }
}

/* WIDTHS */
.q-width-75 {
    width: 75px;
}

@media (min-width: 1200px) {
    // .container {
    //   max-width: 1150px;
    // }
}

@media (min-width: 768px) {
    // .App {
    //   margin-top: 70px;
    // }

    .p-md-5 {
        padding: 3.9rem !important;
    }

    .btn {
        // padding: 14px 24px 12px;
        padding: 16px 24px;
        font-size: 18px;
        line-height: 27px;
    }
}

// .container-fluid {
//   max-width: 1150px;
// }

.cookieConsent {
    h2 {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.4;
        margin: 0 0 5px 0;
    }

    p {
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.4;
        margin: 0 0 5px 0;
    }

    a {
        font-size: 19px;
        color: #fff;
        text-decoration: underline;
    }

    .agree-cookie-btn {
        cursor: pointer;
        margin: 0 15px 15px 15px;
        // margin-top: 1em;
        vertical-align: middle;
        overflow: visible;
        width: auto;
        -moz-box-shadow: inset 0 1px 0 0 #ffffff;
        -webkit-box-shadow: inset 0 1px 0 0 #ffffff;
        box-shadow: inset 0 1px 0 0 #ffffff;
        background-color: #ededed;
        background-image: -moz-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
        background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(5%, #ededed),
            color-stop(100%, #dfdfdf)
        );
        background-image: -webkit-linear-gradient(
            top,
            #ededed 5%,
            #dfdfdf 100%
        );
        background-image: -o-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
        background-image: -ms-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
        background-image: linear-gradient(to bottom, #ededed 5%, #dfdfdf 100%);
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        border-radius: 6px;
        border: 1px solid #dcdcdc;
        color: #000;
        font-family: Arial, sans-serif;
        font-weight: bold;
        padding: 4px 8px;
        text-decoration: none;
        text-shadow: 1px 1px 0 #ffffff;

        &:hover {
            background-color: #dfdfdf;
            background-image: -moz-linear-gradient(
                top,
                #dfdfdf 5%,
                #ededed 100%
            );
            background-image: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(5%, #dfdfdf),
                color-stop(100%, #ededed)
            );
            background-image: -webkit-linear-gradient(
                top,
                #dfdfdf 5%,
                #ededed 100%
            );
            background-image: -o-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
            background-image: -ms-linear-gradient(
                top,
                #dfdfdf 5%,
                #ededed 100%
            );
            background-image: linear-gradient(
                to bottom,
                #dfdfdf 5%,
                #ededed 100%
            );
        }
    }
}

@media (min-width: 768px) {
    .cookieConsent {
        justify-content: space-around !important;
        .agree-cookie-btn {
            margin-top: 1rem;
        }
    }
}

#hubspot-messages-iframe-container {
    z-index: 2147483646 !important;
}
